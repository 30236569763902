<template>
  <v-container v-if="!loading" id="category-table" fluid tag="section">
    <base-material-card
      icon="mdi-office-building"
      inline
      color="secondary"
      class="px-5 py-3 mb-5"
    >
      <template v-slot:after-heading
        ><h1 class="h5">
          <b>{{ $tc("company_partner", 2) }}</b>
        </h1></template
      >
      <v-btn
        @click="handleAddPartnerB2b"
        color="primary"
        rounded
        absolute
        fab
        top
        right
      >
        <v-icon large>mdi-plus</v-icon>
      </v-btn>
      <v-simple-table>
        <thead>
          <tr>
            <th class="text-center">{{ $tc("company_partner", 1) }}</th>
            <th class="text-center">{{ $t("users_partners") }}</th>
            <th class="text-center">{{ $tc("investor", 2) }}</th>
            <th class="text-center">{{ $tc("investment", 2) }}</th>
            <th class="text-center">{{ $t("invested") }}</th>
            <th class="text-center">{{ $t("allocations_pending") }}</th>
            <th class="text-center">{{ $t("edit") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(partner, index) in partnersB2b"
            :key="index"
            data-test="Vehicle:Vehicle:Teste"
          >
            <td class="text-center">{{ partner.name }}</td>
            <td class="text-center">{{ partner.totalPartners }}</td>
            <td class="text-center">{{ partner.totalInvestors }}</td>
            <td class="text-center">{{ partner.totalInvestments }}</td>
            <td class="text-center">
              <span style="display: block">{{
                showCurrencyValue(
                  partner.totalInvestedBRL,
                  partner.totalInvestedUSD,
                  CurrencyEnum.BRL
                )
              }}</span>
              <span>{{
                showCurrencyValue(
                  partner.totalInvestedUSD,
                  partner.totalInvestedBRL,
                  CurrencyEnum.USD
                )
              }}</span>
              <span
                v-if="!partner.totalInvestedBRL && !partner.totalInvestedUSD"
                >-</span
              >
            </td>
            <td class="text-center">
              <span style="display: block">{{
                showCurrencyValue(
                  partner.totalPendingBRL,
                  partner.totalPendingUSD,
                  CurrencyEnum.BRL
                )
              }}</span>
              <span>{{
                showCurrencyValue(
                  partner.totalPendingUSD,
                  partner.totalPendingBRL,
                  CurrencyEnum.USD
                )
              }}</span>
              <span v-if="!partner.totalPendingBRL && !partner.totalPendingUSD"
                >-</span
              >
            </td>
            <td class="text-center">
              <v-btn
                class="px-2 ml-1 secondary"
                min-width="0"
                small
                @click="handleEditPartnerB2b(partner)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-pagination
        color="primary"
        @input="changePage"
        v-model="page"
        :length="pageCount"
      >
      </v-pagination>
    </base-material-card>
    <v-dialog
      v-if="addOrEditPartnerB2bDialog"
      v-model="addOrEditPartnerB2bDialog"
    >
      <DialogAddOrEditPartnerB2b
        :partnerB2bContent="editPartnerB2bContent"
        @closeDialog="handleCloseDialog"
        @saved="handleSavedPartnerB2b"
      />
    </v-dialog>
  </v-container>
  <v-container v-else>
    <v-progress-circular
      style="margin-left: 50%"
      indeterminate
      size="70"
      color="primary"
    ></v-progress-circular>
  </v-container>
</template>

<script>
import ApiService from "@/services/ApiService";
import DialogAddOrEditPartnerB2b from "./DialogAddOrEditPartnerB2b";
import { formatCurrency } from "@/shared/helpers/currencyHelper";
import { CurrencyEnum } from "@/shared/enums/CurrencyEnum";

export default {
  name: "Vehicle",
  components: { DialogAddOrEditPartnerB2b },
  data() {
    return {
      apiService: new ApiService(),
      partnersB2b: null,
      loading: false,
      addOrEditPartnerB2bDialog: false,
      editPartnerB2bContent: null,
      getPartnerB2bLoading: false,
      partnerB2bToEditId: null,
      page: 1,
      pageCount: 1,
      formatCurrency,
      CurrencyEnum,
    };
  },
  watch: {},
  async created() {
    await this.getPartnersB2b();
  },
  methods: {
    showCurrencyValue(valueToFormatted, valueToCompare, currency) {
      if (valueToFormatted) {
        return this.formatCurrency(currency, valueToFormatted);
      } else {
        if (valueToCompare) {
          return "-";
        }
      }
    },
    async getPartnersB2b() {
      this.loading = true;
      let currentPage = this.page;

      let params = { Page: currentPage };

      await this.apiService
        .postRequest("partnerb2b/list/filter", params)
        .then((result) => {
          const totalItemsPorPage = result.content.perPage;

          this.page = result.content.currentPage;
          this.pageCount = Math.ceil(result.content.total / totalItemsPorPage);
          this.partnersB2b = result.content.items;
        })
        .catch((err) => {
          this.$toast.error("error_occoured");
          return err;
        });
      this.loading = false;
    },
    changePage() {
      this.getPartnersB2b();
    },
    handleEditPartnerB2b(partner) {
      this.$router.push(`partnerb2b/${partner.id}`);
    },
    handleCloseDialog() {
      this.addOrEditPartnerB2bDialog = false;
    },
    handleSavedPartnerB2b() {
      this.getPartnersB2b();
      this.addOrEditPartnerB2bDialog = false;
    },
    handleAddPartnerB2b() {
      this.addOrEditPartnerB2bDialog = true;
    },
  },
};
</script>
